import img2 from "../assets/images/ahm2.jpeg";
import img7 from "../assets/images/ahm7.jpg";

const newPPTdata = [
  {
    image:img2,
    idno: "AHM 001-002 (A)",
    year: "2022",
    zone_name: "Ahmedabad",
    area: "132ft. Ring Rd.",
    location: "132ft. Ring Rd., Pallav 4 Road Junction, Tow. Ranna Park ",
    media: "Hoarding",
    type: "BL",
    width: "61",
    height: "15",
    availability: " -",
    rate: "",
    lat_long: "https://www.google.com/maps/place/ -, -",
  },
  {
    image:img7,
    idno: "AHM 002 (A)",
    year: "2022",
    zone_name: "Ahmedabad",
    area: "132ft. Ring Rd.",
    location: "132ft. Ring Rd., Pallav 4 Road Junction, Tow. Ranna Park (Left)",
    media: "Hoarding",
    type: "BL",
    width: "30",
    height: "15",
    availability: "03.09.23",
    rate: "",
    lat_long: "https://www.google.com/maps/place/23.061785,72.552196",
  },
  {
    image:
      "https://kaushik-outdoors.s3.ap-south-1.amazonaws.com/static/media/ahm3.jpeg",
    idno: "AHM 003-004 (A)",
    year: "2022",
    zone_name: "Ahmedabad",
    area: "132ft. Ring Rd.",
    location:
      "132ft. Ring Rd., Pallav Cross Road, Fcg. Naranpura AEC Cross Road ",
    media: "Unipole",
    type: "BL",
    width: "20",
    height: "21",
    availability: " -",
    rate: "",
    lat_long: "https://www.google.com/maps/place/ -, -",
  },
  {
    image:
      "https://kaushik-outdoors.s3.ap-south-1.amazonaws.com/static/media/ahm4.jpeg",
    idno: "AHM 004 (A)",
    year: "2022",
    zone_name: "Ahmedabad",
    area: "132ft. Ring Rd.",
    location:
      "132ft. Ring Rd., Pallav Cross Road, Fcg. Naranpura AEC Cross Road (Down)",
    media: "Unipole",
    type: "BL",
    width: "20",
    height: "10",
    availability: "01.12.23",
    rate: "",
    lat_long: "https://www.google.com/maps/place/23.061371,72.551934",
  },
];

const MediumList = [
  { id: "1", name: "Billboards", name2: "Billboards" },
  { id: "2", name: "BRTS Bus Station", name2: "brts bus station" },
  { id: "3", name: "Railway Station", name2: "railway station" },
  { id: "4", name: "Indicators", name2: "indicators" },
  { id: "5", name: "Mehsana - Bus Stand", name2: "mehsana-bus stand" },
  { id: "6", name: "Vadodara - Pillers", name2: "vadodara pillers" },
];

// const RateList = [
//   { id: 1, name: "50%" },
//   { id: 2, name: "60%" },
//   { id: 3, name: "70%" },
//   { id: 4, name: "75%" },
//   { id: 5, name: "80%" },
//   { id: 6, name: "85%" },
//   { id: 7, name: "90%" },
//   { id: 8, name: "95%" },
//   { id: 9, name: "100%" },
// ];
const Rates = [
  { label: "100%", value: 100 },
  { label: "95%", value: 95 },
  { label: "90%", value: 90 },
  { label: "85%", value: 85 },
  { label: "80%", value: 80 },
  { label: "75%", value: 75 },
  { label: "70%", value: 70 },
  { label: "65%", value: 65 },
  { label: "60%", value: 60 },
  { label: "No Rate", value: "noRate" },
];
// IdList, IdList2, BRTSAreaList, ZoneList1, MediaList, stationNameList,
// availabilityDateList, pillersNumList, ImageList, LongitudeList, LatitudeList, WidthList,  HeightList, TypeList, LocationList,
// indicatorNumList, indicatorSizeList, TotalSquareFtList,
export {
  MediumList,
  Rates,
  newPPTdata,
};
