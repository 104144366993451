import pptxgen from "pptxgenjs";
import { useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import Theme from "../../assets/images/PPT1stpage.jpg";
import logo from "../../assets/images/Logo.png";
import facebook from "../../assets/images/facebook2.png";
import twitter from "../../assets/images/twitter2.png";
import youtube from "../../assets/images/youtube2.png";
import instagram from "../../assets/images/instagram2.png";
import linkedin from "../../assets/images/linkedin2.png";
import lastPPT from "../../assets/images/lastPage_ppt.jpg";
import termsImgPPT from "../../assets/images/terms_white.jpg";
import whiteLayout from "../../assets/images/n4.jpg";
import { Button, Col, Row } from "reactstrap";
import { FilterSelection } from "../FilterSelection/FilterSelection";
import "./DownloadFiles.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import CustomLoader from "../../Components/customLoader/CustomLoader";
import { MediumList } from "../../constants/data";
import { FaDownload } from "react-icons/fa";
import { Header } from "../../Components/Header/Header";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";

const ExcelJS = require("exceljs");

export default function DownloadFiles({ loading, setOpenModal, user_details, setShowAlert }) {
  const [PPTdata, setPPTdata] = useState([]);
  const [pagePPTdata, setPagePPTdata] = useState([]);
  const [medium, setMedium] = useState("1");
  const [checkbox, setCheckbox] = useState({ 0: false });
  const [imgModal, setImgModal] = useState(false);
  const [page, setPage] = useState(1);
  const [total_page_count, setTotal_page_count] = useState(1);
  const [imgURL, setImgURL] = useState("");
  const [cardLoading, setCardLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showRate, setShowRate] = useState("noRate");
  const [showLatitude, setShowLatitude] = useState(false);
  const navigate = useNavigate();
  const myRef = useRef(null);

  const downloadImage = async () => {
    let imageSrc =
      "https://www.kaushikoutdoors.com/administrator/upload/our_products//_a808d3dda1c6693a952094e946455acd.jpg";
    let imageSrc1 =
      "https://kaushik-outdoors.s3.ap-south-1.amazonaws.com/static/media/ahm3.jpeg";
    let imageSrc2 =
      "https://www.kaushikoutdoors.com/administrator/upload/our_products//_728417da52a1b40e1fbec879fb528284.JPG";
    const response = await fetch(imageSrc2, { mode: "no-cors" });
    const blobImage = await response.blob();
    const href = URL.createObjectURL(blobImage);
    const anchorElement = document.createElement("a");
    anchorElement.href = href;
    anchorElement.download = "my-image.jpg";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  };

  const handleCheck = (n) => {
    const checked = !checkbox[n];
    setCheckbox({ ...checkbox, [n]: checked });
  };

  const generatePPT = async () => {
    setCardLoading(true);
    // console.log(checkbox);
    let data = [];
    for (let i = 0; i < PPTdata.length; i++) {
      if (checkbox[i] === true) {
        data.push(PPTdata[i]);
      }
    }
    const d1 = new Date();
    const d = d1.toString();
    const date = `${d.slice(8, 10)} ${d.slice(4, 7)}, ${d1.getFullYear()}`;
    const showTime = d1.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    try {
      let pres = new pptxgen();
      pres.layout = "LAYOUT_16x9";
      let slide1 = pres.addSlide();
      slide1.addImage({
        path: Theme,
        x: "0%",
        y: "0%",
        w: "100%",
        h: "100%",
      });
      slide1.addText(date, {
        x: 8.8,
        y: 5.3,
        bold: "true",
        color: "363636",
        fontSize: "12",
      });
      slide1.addText(showTime, {
        x: 8.8,
        y: 5.5,
        color: "363636",
        bold: "true",
        fontSize: "12",
      });

      pres.defineSlideMaster({
        title: "MASTER_SLIDE",
        objects: [
          {
            image: {
              path: whiteLayout,
              x: 0,
              y: 0,
              w: "100%",
              h: "100%",
            },
          },
        ],
      });

      let slides = [];
      data.map((item, index) => {
        slides[index] = pres.addSlide({ masterName: "MASTER_SLIDE" });
        // const blobImage = fetch(item.image).then(response=>response.blob())
        // const response = await fetch(imageSrc);
        // const blobImage = await response.blob();
        // if(blobImage){}
        slides[index].addImage({
          // data: blobImage ,
          path: item.image,
          // data: "image/png;base64,iVtDafDrBF[...]="
          // path: item.image.replace("https://www.kaushikoutdoors.com",""),
          x: 1.2,
          y: 0.02,
          w: "74%",
          h: "86%",
        });

        if ((medium === "1" || medium === "5") && showLatitude !== "false") {
          let arrTabRows = [
            [
              { text: `   Availability:     ${item.availability}` },
              {
                text: `   Rate:     ${showRate !== "noRate" ? item.rate : ""}`,
              },
            ],
            [
              { text: `   Latitude & Longitude :` },
              {
                text: `${item.latitude} & ${item.longitude}`,
                options: {
                  color: "#000091",
                  hyperlink: {
                    url: `https://www.google.com/maps/place/${item.latitude},${item.longitude}`,
                    tooltip: "Location",
                  },
                },
              },
            ],
          ];
          slides[index].addTable(arrTabRows, {
            x: 1.21,
            y: 4.835,
            w: "74%",
            h: 0.7,
            colW: [3.69, 3.69],
            fontSize: "14",
            border: { type: "solid", pt: "3", color: "#000000" },
          });
        } else {
          let arrTabRows = [
            [
              { text: `   Availability:     ${item.availability}` },
              {
                text: `   Rate:     ${showRate !== "noRate" ? item.rate : ""}`,
              },
            ],
          ];
          slides[index].addTable(arrTabRows, {
            x: 1.21,
            y: 4.835,
            w: "74%",
            h: 0.35,
            colW: [3.69, 3.69],
            fontSize: "14",
            border: { type: "solid", pt: "3", color: "#000000" },
          });
        }
        return 0;
      });
      pres.defineSlideMaster({
        title: "MASTER2",
        objects: [
          {
            image: {
              path: termsImgPPT,
              x: 0,
              y: 0,
              w: "100%",
              h: "100%",
            },
          },
        ],
      });
      let termsSlide = pres.addSlide({ masterName: "MASTER2" });
      termsSlide.background = {
        fill: "000000",
      };
      pres.defineSlideMaster({
        title: "MASTER3",
        objects: [
          {
            image: {
              path: lastPPT,
              x: 0,
              y: 0,
              w: "100%",
              h: "100%",
            },
          },
        ],
      });
      let lastSlide = pres.addSlide({ masterName: "MASTER3" });
      lastSlide.addText(
        `${user_details.first_name} ${user_details.last_name} | ${user_details.designation_name}`,
        {
          x: 4.6,
          y: 2.4,
          w: 3,
          h: 0.3,
          fontSize: "11",
          bold: true,
        }
      );
      lastSlide.addText(user_details.mobile, {
        x: 4.6,
        y: 2.6,
        w: 3,
        h: 0.3,
        fontSize: "11",
        bold: true,
      });
      lastSlide.addText(user_details.email, {
        x: 4.45,
        y: 2.85,
        w: 3,
        h: 0.3,
        fontSize: "11",
      });
      lastSlide.addImage({
        path: facebook,
        x: "78%",
        y: "92%",
        w: 0.3,
        h: 0.3,
        shadow: {
          type: "outer",
          blur: 2,
          color: "000000",
          opacity: 0.5,
          offset: 4,
          angle: 90,
        },
        hyperlink: {
          url: "https://www.facebook.com/kaushikoutdoors",
          tooltip: "Visit facebook",
        },
      });

      lastSlide.addImage({
        path: linkedin,
        x: "82%",
        y: "92%",
        w: 0.3,
        h: 0.3,
        shadow: {
          type: "outer",
          blur: 2,
          color: "000000",
          opacity: 0.5,
          offset: 4,
          angle: 90,
        },
        hyperlink: {
          url: "https://www.linkedin.com/company/kaushik-outdoors/",
          tooltip: "Visit Linkedin",
        },
      });

      lastSlide.addImage({
        path: twitter,
        x: "86%",
        y: "92%",
        w: 0.3,
        h: 0.3,
        shadow: {
          type: "outer",
          blur: 2,
          color: "000000",
          opacity: 0.5,
          offset: 4,
          angle: 90,
        },
        hyperlink: {
          url: "https://twitter.com/kaushikoutdoors",
          tooltip: "Visit Twitter",
        },
      });

      lastSlide.addImage({
        path: instagram,
        x: "90%",
        y: "92%",
        w: 0.3,
        h: 0.3,
        shadow: {
          type: "outer",
          blur: 2,
          color: "000000",
          opacity: 0.5,
          offset: 4,
          angle: 90,
        },
        hyperlink: {
          url: "https://www.instagram.com/kaushikoutdoors/",
          tooltip: "Visit Instagram",
        },
      });

      lastSlide.addImage({
        path: youtube,
        x: "94%",
        y: "92%",
        w: 0.3,
        h: 0.3,
        shadow: {
          type: "outer",
          blur: 2,
          color: "000000",
          opacity: 0.5,
          offset: 4,
          angle: 90,
        },
        hyperlink: {
          url: "https://www.youtube.com/@kaushikoutdoors1812",
          tooltip: "Visit Youtube",
        },
      });

      pres
        .writeFile({
          fileName: `Presentation_${d.slice(0, 24).replace(/ /g, "_")}.pptx`,
        })
        .then((s) => {
          // console.log("first", s);
          setCardLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setCardLoading(false);
          setShowAlert({open: true, message:e.message})
        });
    } catch (e) {
      setCardLoading(false);
      console.log("----e", e);
      setShowAlert({open: true, message:e.message})
    }
  };

  const getHeading = () => {
    let h = [];
    if (medium === "1" || medium === "5") {
      h = [
        [
          "SR NO",
          "ID NO",
          "ZONE",
          "CITY/AREA",
          "LOCATION",
          "MEDIA",
          "TYPE",
          "WIDTH",
          "HEIGHT",
          "AVAILABILITY",
        ],
      ];
      if (showLatitude !== "false") {
        // console.log("first", 393);
        h[0] = h[0].concat(["LONGITUDE & LATITUDE"]);
      }
    }
    if (medium === "2") {
      h = [
        ["SR NO", "ID NO", "AREA", "LOCATION", "TOTAL SQ.FT.", "AVAILABILITY"],
      ];
    }
    if (medium === "3") {
      h = [
        [
          "SR NO",
          "ID NO",
          "STATION NAME",
          "TYPE",
          "WIDTH",
          "HEIGHT",
          "AVAILABILITY",
        ],
      ];
    }
    if (medium === "4") {
      h = [
        [
          "SR NO",
          "ID NO",
          "AREA",
          "LOCATION",
          "SIZE",
          "TOTAL NO. OF INDICATORES",
          "AVAILABILITY",
        ],
      ];
    }

    if (medium === "6") {
      h = [
        [
          "SR NO",
          "ID NO",
          "AREA",
          "LOCATION",
          "WIDTH",
          "HEIGHT",
          "TOTAL NO. OF PILLERS",
          "AVAILABILITY",
        ],
      ];
    }

    if (showRate !== "noRate") h[0].push("RATE");
    return h;
  };

  const getBodyData = () => {
    let data = [];
    let j = 1;
    let data2 = [];
    for (let i = 0; i < PPTdata.length; i++) {
      if (checkbox[i] === true) {
        data2.push(PPTdata[i]);
      }
    }
    // console.log(data2);
    for (let i = 0; i < data2.length; i++) {
      // console.log(data2[i], 508);
      let obj = {};
      if (medium === "1" || medium === "5") {
        // SR NO	ID NO	ZONE	CITY/AREA	LOCATION	MEDIA	TYPE	WIDTH	HEIGHT	AVAILABILITY	LONGITUDE	LATITUDE	RATE
        obj = {
          sr_no: j,
          id: data2[i].idno,
          zone: data2[i].zone_name,
          area: data2[i].area,
          location: data2[i].location,
          media: data2[i].media,
          type: data2[i].type,
          w: data2[i].width,
          h: data2[i].height,
          availability: data2[i].availability,
        };
        if (showLatitude !== "false") {
          // console.log("first", 475);
          obj = {
            ...obj,
            lat_long: `${data2[i].latitude} & ${data2[i].longitude}`,
          };
        }
        j += 1;
      }
      if (medium === "2") {
        obj = {
          sr_no: j,
          id: data2[i].idno,
          area: data2[i].area,
          location: data2[i].location,
          totalSqFt: data2[i].total_sq_ft,
          availability: data2[i].availability,
        };
        j += 1;
      }
      if (medium === "3") {
        obj = {
          sr_no: j,
          id: data2[i].idno,
          stationName: data2[i].station_name,
          type: data2[i].type,
          w: data2[i].width,
          h: data2[i].height,
          availability: data2[i].availability,
        };
        j += 1;
        delete obj.image;
      }
      if (medium === "4") {
        obj = {
          sr_no: j,
          id: data2[i].idno,
          area: data2[i].area,
          location: data2[i].location,
          size: data2[i].size,
          totalNoIndicators: data2[i].tot_no_of_indicators,
          availability: data2[i].availability,
        };
        j += 1;
      }
      if (medium === "6") {
        obj = {
          sr_no: j,
          id: data2[i].idno,
          area: data2[i].area,
          location: data2[i].location,
          width: data2[i].width,
          height: data2[i].height,
          no_of_piller: data2[i].no_of_piller,
          availability: data2[i].availability,
        };
        // "Area",
        //   "Location",
        //   "Width",
        //   "Height",
        //   "Total No. of PILLERS",
        j += 1;
      }
      // if (showRate) obj.rate = PPTdata[i].rate;
      // if (showRate !== "noRate") obj.rate = rateValueList[i];
      if (showRate !== "noRate") obj.rate = data2[i].rate;
      data.push(obj);
    }
    return data;
  };

  const getTerms = () => {
    let terms1 = [
      [" ", "Terms & Conditions:"],
      [1, "Flex Printing and Mounting Charges will be 20 Rs. Per Sq. Ft."],
    ];
    if (medium === "1") {
      terms1 = [
        [" ", "Terms & Conditions:"],
        [1, "Flex Printing Charges will be EXTRA."],
        [2, "Flex Fixing Charges will be EXTRA."],
      ];
    }
    if (medium === "3") {
      terms1 = [
        [" ", "Terms & Conditions:"],
        [1, "Flex Printing Charges will be EXTRA @Rs. 7/- Per Sq.Ft."],
        [2, "Flex Fixing Charges will be EXTRA @Rs. 3/- Per Sq.Ft."],
      ];
    }
    let m = 1;
    if (medium === "3" || medium === "1") m = 2;
    let terms2 = [
      [m + 1, "18% GST will be EXTRA."],
      [
        m + 2,
        "If the client requires Flex and wants it to be removed, an additional charge of Rs. 1/- per square feet will be applied.",
      ],
      [m + 3, "We do not accept or process any printing jobs after 6:00 PM."],
      [
        m + 4,
        "You will receive the installation photographs within 24 working hours.",
      ],
      [
        m + 5,
        "Photographs of the displayed advertisement will be available only after 48 working hours.",
      ],
      [
        m + 6,
        "Please be aware that the confirmation of the mentioned hoarding sites is subject to availability.",
      ],
      [
        m + 7,
        "We are not responsible for any misplacement or damage of the flex material.",
      ],
      [
        m + 8,
        "Kaushik Outdoors will not be held responsible for any damages caused by electrical problems resulting from AEC (Ahmedabad Electricity Company) or natural calamities.",
      ],
      [
        m + 9,
        "PAN (Permanent Account Number) and GSTIN (Goods and Services Tax Identification Number) are required at the time of booking for billing.",
      ],
    ];
    let terms = [...terms1, ...terms2];
    return terms;
  };

  const exportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    setCardLoading(true);
    let jsonData = getBodyData();
    const rows = jsonData.map((item) => Object.values(item));
    // Add column headers and rows to the worksheet
    // worksheet.addRow(columns);
    worksheet.addRows(getHeading());
    rows.forEach((row) => {
      if (medium === "1" || medium === "5") {
        row[7] = Number(row[7]);
        row[8] = Number(row[8]);
      }
      if (medium === "2") {
        row[4] = Number(row[4]);
      }
      if (medium === "3" || medium === "6") {
        row[4] = Number(row[4]);
        row[5] = Number(row[5]);
      }
      if (medium === "4") {
        row[5] = Number(row[5]);
      }
      worksheet.addRow(row);
    });
    if (showLatitude !== "false") {
      let arr = ["LONGITUDE & LATITUDE"];
      rows.forEach((row) => arr.push(row[10]));
      arr.forEach((value, index) => {
        if (index !== 0) {
          let cell = worksheet.getCell(index + 1, 11);
          cell.value = {
            text: value,
            hyperlink:
              "https://www.google.com/maps/place/" + value.replace(" & ", ","),
          };
          cell.font = {
            color: { argb: "000091" }, // Red color
          };
        }
      });
    }

    worksheet.getRow(1).font = { bold: true };
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          vertical: "center",
          horizontal: "left",
        };
        // cell.rul
      });
    });

    worksheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => v.toString().length);
      const maxLength = Math.max(
        ...lengths.filter((v) => typeof v === "number")
      );
      column.width = maxLength + 2;
    });
    if (medium === "1" || medium === "5") {
      console.log(worksheet.getColumnKey, 658);
      worksheet.getColumn(7).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(8).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(9).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(12).alignment = {
        horizontal: "center",
      };
    }
    if (medium === "2") {
      worksheet.getColumn(5).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(7).alignment = {
        horizontal: "center",
      };
    }

    if (medium === "3") {
      console.log(worksheet.getColumnKey, 658);
      worksheet.getColumn(4).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(5).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(6).alignment = {
        horizontal: "center",
      };
      worksheet.getColumn(8).alignment = {
        horizontal: "center",
      };
    }

    let n = jsonData.length + 5;
    worksheet.addRows([[""], [""], [""]]);
    worksheet.addRows(getTerms());
    // Merge cells A20 to H20
    for (let i = n; i < n + 13; i++) {
      worksheet.mergeCells(`B${i}:L${i}`);
      worksheet.getCell(`B${i}`).alignment = {
        horizontal: "left",
      };
    }
    worksheet.getCell("B"+n).font = { bold: true };
    worksheet.getRow(1).alignment = {
      vertical: "center",
      horizontal: "center",
    };
    worksheet.getColumn(1).alignment = {
      vertical: "center",
      horizontal: "center",
    };
    let siteName = "";
    MediumList.forEach((item) => {
      // console.log(typeof filter.medium, typeof item.id);
      if (item.id === medium) siteName = item.name;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const d1 = new Date();
    const d = d1.toString();
    a.download = `Shortlisted Sites ${siteName} ${d
      .slice(0, 24)
      .replace(/ /g, "_")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);

    setTimeout(() => {
      setCardLoading(false);
    }, 1000);
  };

  const generatePDF = async () => {
    setCardLoading(true);
    setTimeout(() => {
      downloadPDF();
    }, 1000);
  };

  const downloadPDF = async () => {
    try {
      // var doc = new jsPDF("landscape", "px", "a4");
      let doc = await new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [640, 360],
      });
      // var img = new Image();
      // img.src = logo;
      // doc.addImage("https://scx2.b-cdn.net/gfx/news/2020/nature.jpg", "jpg", 0, 0, 650, 448);
      doc.addImage(Theme, "jpg", 0, 0, 640, 360);
      const d1 = new Date();
      const d = d1.toString();
      const date = `${d.slice(8, 10)} ${d.slice(4, 7)}, ${d1.getFullYear()}`;
      const showTime = d1.toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      doc.text(540, 320, date);
      doc.text(540, 340, showTime);

      let data = [];
      for (let i = 0; i < PPTdata.length; i++) {
        if (checkbox[i] === true) {
          data.push(PPTdata[i]);
        }
      }
      // for (let i = 0; i < PPTdata.length; i++) {
      //   if (checkbox[i + 1] === true) {
      //     data.push(PPTdata[i]);
      //   }
      // }

      data.map(async (item, index) => {
        doc.addPage();
        // const imageType = item.image.slice(-3);
        const imageType = item.image.split(".").pop();
        doc.addImage(whiteLayout, "jpg", 0, 0, 640, 360);
        if (imageType === "svg") {
          doc.addImage(logo, "png", 70, 10, 480, 300);
          // doc.addImage(logo, "png", 70, 50, 480, 300);
        } else {
          doc.addImage(item.image, 'jpeg', 70, 5, 480, 300);
        }
        if ((medium === "1" || medium === "5") && showLatitude !== "false") {
          // doc.addImage(item.image.replace("https://www.kaushikoutdoors.com",""), "JPG", 70, 50, 480, 300);
          // doc.addFont("helvetica", "normal");
          // doc.rect(110, 350, 410, 50, "S");
          // doc.text(90, 367, `Availability:     ${item.availability}`);
          doc.rect(70, 305, 480, 50, "S");
          doc.line(70, 330, 550, 330);
          doc.line(310, 305, 310, 355);
          doc.text(90, 322, `Availability:     ${item.availability}`);
          doc.text(
            330,
            322,
            `Rate:             ${showRate !== "noRate" ? item.rate : ""}`
          );
          // doc.text(90, 347, `Latitude:         ${item.latitude}`);
          // doc.text(330, 347, `Longitude:        ${item.longitude}`);
          doc.text(90, 347, `Latitude & Longitude :        `);
          doc.setTextColor(0, 0, 145);
          doc.textWithLink(`${item.latitude} & ${item.longitude}`, 330, 347, {
            url: `https://www.google.com/maps/place/${item.latitude},${item.longitude}`,
          });
          doc.setTextColor(0, 0, 0);
        } else {
          doc.rect(70, 305, 480, 25, "S");
          // doc.line(70, 330, 550, 330);
          doc.line(310, 305, 310, 330);
          doc.text(90, 322, `Availability:     ${item.availability}`);
          doc.text(
            330,
            322,
            `Rate:             ${showRate !== "noRate" ? item.rate : ""}`
          );
        }
      });
      doc.addPage();
      doc.addImage(termsImgPPT, "jpg", 0, 0, 640, 360);
      doc.addPage();
      doc.addImage(lastPPT, "jpg", 0, 0, 640, 360);
      doc.link(450, 330, 20, 20, {
        url: "https://www.facebook.com/kaushikoutdoors",
      });
      doc.addImage(facebook, "png", 450, 330, 20, 20);
      doc.link(485, 330, 20, 20, {
        url: "https://www.linkedin.com/company/kaushik-outdoors/",
      });
      doc.addImage(linkedin, "png", 485, 330, 20, 20);
      doc.link(520, 330, 20, 20, {
        url: "https://twitter.com/kaushikoutdoors",
      });
      doc.addImage(twitter, "png", 520, 330, 20, 20);
      doc.link(555, 330, 20, 20, {
        url: "https://www.instagram.com/kaushikoutdoors/",
      });
      doc.addImage(instagram, "png", 555, 330, 20, 20);
      doc.link(590, 330, 20, 20, {
        url: "https://www.youtube.com/@kaushikoutdoors1812",
      });
      doc.addImage(youtube, "png", 590, 330, 20, 20);
      doc.setFontSize(13);
      doc.text(
        300,
        175,
        `${user_details.first_name} ${user_details.last_name} | ${user_details.designation_name}`
      );
      // doc.text(300, 190, "+91 8238 00 3334");
      doc.text(300, 190, user_details.mobile);
      doc.setFontSize(11);
      // doc.text(300, 205, "media.planner@kaushikoutdoors.com");
      doc.text(300, 205, user_details.email);
      doc.save(`Sites ${d.slice(0, 24).replace(/ /g, "_")}.pdf`);
      setCardLoading(false);
      // setTimeout(() => {
      // }, 3000);
    } catch (error) {
      console.log(error, 777);
      setCardLoading(false);
      setShowAlert({open: true, message:error.message})
    }
  };

  const generatePDFtable = () => {
    setCardLoading(true);
    var doc = new jsPDF("landscape", "px", "a4");
    autoTable(doc, {
      theme: "grid",
      head: getHeading(),
      body: getBodyData().map((item) => Object.values(item)),
      styles: { halign: "center" },
    });
    // doc.addPage();

    autoTable(doc, {
      theme: "grid",
      head: [],
      body: getTerms(),
      styles: { margin: { top: 200 } },
      columnStyles: {
        0: { halign: "center", cellWidth: 40 },
      },
    });
    const d1 = new Date();
    const d = d1.toString();
    let siteName = "";
    MediumList.forEach((item) => {
      // console.log(typeof filter.medium, typeof item.id);
      if (item.id === medium) siteName = item.name;
    });
    doc.save(
      `Sites_table ${siteName} ${d.slice(0, 24).replace(/ /g, "_")}.pdf`
    );
    setTimeout(() => {
      setCardLoading(false);
    }, 1000);
  };

  useEffect(() => {
    let n = (page - 1) * 48;
    setPagePPTdata(PPTdata.slice(n, n + 48));
  }, [page]);

  useEffect(() => {
    const filterObj = JSON.parse(localStorage.getItem("filterObj"));
    if (filterObj) {
      applyFilter(filterObj);
    }
  }, []);

  const applyFilter = async (filterObj) => {
    let rate = localStorage.getItem("ratePercentage");
    setShowRate(rate);
    let lat_long = localStorage.getItem("showLatitude");
    setShowLatitude(lat_long);
    setCardLoading(true);
    myRef.current?.scrollIntoView();
    // window.scrollTo({ top: "900px", left: 0, behavior: 'smooth' });
    setMedium(filterObj.medium);
    try {
      let formData = new FormData();
      formData.append("token", routes.TOKEN);
      formData.append("session_token", user_details.session_token);
      let m = "";
      MediumList.forEach((i) => {
        if (i.id === filterObj.medium) m = i.name2;
      });
      formData.append("medium", m);
      let selected = [];
      for (let x in filterObj) {
        if (x !== "medium" && filterObj[x].length > 0) {
          let arr1 = filterObj[x].map((i) => i.value);
          if (arr1[0] === "*") arr1.shift();
          selected.push({
            name: x,
            value: JSON.stringify(arr1).slice(1, -1).replaceAll('"', "'"),
          });
          formData.append(
            `${x}`,
            JSON.stringify(arr1).slice(1, -1).replaceAll('"', "'")
          );
        }
      }
      const dataRes = await fetch(`${routes.API_URL}${routes.FILTER}`, {
        method: "POST",
        body: formData,
      });
      const json = await dataRes.json();
      if (json) {
        setCardLoading(false);
        setShowResult(true);
        if (json.status_code === "1") {
          let arrObj1 = json.data;
          let totalPage = Math.ceil(Number(json.data_count) / 48);
          setTotal_page_count(totalPage);
          json.data.map((item, index) => {
            let arr1;
            arrObj1[index]["index_id"] = index;
            if (rate !== "noRate") {
              arrObj1[index]["rate"] = (item.rate * rate) / 100;
            } else {
              arrObj1[index]["rate"] = "";
            }
            if (item.lat_long && lat_long !== "false") {
              arr1 = item.lat_long.split("/").slice(-1)[0].split(",");
              arrObj1[index]["latitude"] = arr1[0];
              arrObj1[index]["longitude"] = arr1[1];
            } else {
              arrObj1[index]["latitude"] = "";
              arrObj1[index]["longitude"] = "";
            }
          });
          setPPTdata(arrObj1);
          setPage(1);
          if (totalPage > 1) {
            setPagePPTdata(arrObj1.slice(0, 48));
          } else {
            setPagePPTdata(arrObj1);
          }
          let obj2 = { 0: true };
          json.data.forEach((item, index) => {
            obj2[index] = true;
          });
          setCheckbox(obj2);
          myRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        if (json.status_code === "2") {
          localStorage.removeItem("user_details");
          localStorage.removeItem("filterObj");
          navigate("/login");
        }
      }
    } catch (error) {
      console.log(error);
      setPPTdata([]);
      setCardLoading(false);
      setShowAlert({open: true, message:error.message})
    }
    // setShowFilter(false);
  };

  return (
    <div>
      <Header
        setOpenModal={setOpenModal}
        login={true}
        user_details={user_details}
      />
      {loading ? (
        <CustomLoader />
      ) : (
        <div id="filterPage">
          <FilterSelection
            applyFilter={applyFilter}
            user_details={user_details}
            setShowAlert={setShowAlert}
          />
          <div ref={myRef}>
            {cardLoading ? (
              <CustomLoader />
            ) : (
              showResult && (
                <div>
                  <div className="total-result">
                    <Row className="p-3">
                      <Col sm={6} md={3}>
                        <b>Total Results: </b> &nbsp; {PPTdata.length}
                      </Col>
                      <Col sm={6} md={3}>
                        <b> Selected: </b>&nbsp;
                        {Object.values(checkbox).reduce(
                          (total, n) => total + Number(n),
                          0
                        )}
                      </Col>
                      <Col sm={6} md={3}>
                        {" "}
                        <b> Medium: </b>&nbsp;
                        {MediumList.filter((i) => i.id === medium)[0]["name2"]}
                      </Col>
                      <Col sm={6} md={3}>
                        {" "}
                        <b>Rate(%): </b>&nbsp; {showRate}{" "}
                      </Col>
                    </Row>
                    <div className="download-button">
                      <Button color="success" onClick={() => generatePPT()}>
                        <FaDownload />
                        <span>PPT</span>
                        {/* &nbsp; {loaderFor.ppt && (
                  <TailSpin color="#fff" height={20} width={20} />
                )} */}
                      </Button>

                      <Button color="success" onClick={() => generatePDF()}>
                        <FaDownload />
                        <span> PDF (PPT)</span>
                      </Button>

                      <Button color="success" onClick={() => exportExcel()}>
                        <FaDownload />
                        <span>Excel</span>
                      </Button>

                      <Button
                        color="success"
                        onClick={() => generatePDFtable()}
                      >
                        <FaDownload />
                        <span> PDF (Excel)</span>
                      </Button>
                    </div>
                  </div>
                  {pagePPTdata.length > 0 ? (
                    <Row>
                      {pagePPTdata.map((item, index) => (
                        <Col
                          sm={12}
                          md={6}
                          lg={4}
                          xl={3}
                          key={index}
                          className="p-0"
                        >
                          <div className="card">
                            <input
                              type="checkbox"
                              name={item.index_id}
                              className="tableCheckbox"
                              checked={checkbox[item.index_id]}
                              onChange={() => handleCheck(item.index_id)}
                            />

                            <img
                              src={item.image}
                              width="270px"
                              height="180px"
                              alt="img1"
                              className="tableIMG"
                              onClick={() => {
                                setImgURL(item.image);
                                setImgModal(true);
                              }}
                            />
                            <div style={{ minHeight: "260px" }}>
                              <div>
                                <p
                                  className={
                                    checkbox[item.index_id]
                                      ? "card-id-btn active-card-id"
                                      : "card-id-btn"
                                  }
                                  onClick={() => handleCheck(item.index_id)}
                                >
                                  {item.idno}
                                </p>

                                {medium === "1" && (
                                  <Row>
                                    <Col xs={3} className="label-text">
                                      <b>Zone :</b>
                                    </Col>
                                    <Col className="text2">
                                      {" "}
                                      {item.zone_name}
                                    </Col>
                                  </Row>
                                )}
                                {medium !== "3" && (
                                  <Row>
                                    <Col xs={3} className="label-text">
                                      <b>Area :</b>
                                    </Col>
                                    <Col className="text2"> {item.area}</Col>
                                  </Row>
                                )}

                                {/* {medium === "1" && ()} */}
                                {medium !== "2" && medium !== "3" && (
                                  <Row>
                                    <b>Location :</b>
                                    <span>{item.location}</span>
                                  </Row>
                                )}

                                {medium === "2" && (
                                  <Row>
                                    <Col>
                                      <b>Total Sq.Ft. :</b>&emsp;
                                      <span className="text2">
                                        {item.total_sq_ft}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                                {medium === "2" && (
                                  <Row>
                                    <Col>
                                      <b>Location :</b>&emsp;
                                      <span className="text2">
                                        {item.location}
                                      </span>
                                    </Col>
                                  </Row>
                                )}

                                {medium === "3" && (
                                  <Row>
                                    <Col>
                                      <b>Station Name :</b>&emsp;
                                      <span className="text2">
                                        {item.station_name}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                                {medium === "3" && (
                                  <Row>
                                    <Col>
                                      <b>Type :</b>&emsp;
                                      <span className="text2">{item.type}</span>
                                    </Col>
                                  </Row>
                                )}
                                {medium === "4" && (
                                  <Row>
                                    <Col>
                                      <b>Size :</b>&emsp;
                                      <span className="text2">{item.size}</span>
                                    </Col>
                                  </Row>
                                )}
                                {medium === "4" && (
                                  <Row>
                                    <Col>
                                      <b>TOTAL NO. OF INDICATORES :</b>&emsp;
                                      <span className="text2">
                                        {item.tot_no_of_indicators}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                                {medium === "6" && (
                                  <Row>
                                    <Col>
                                      <b> Total No. of Pillers:</b>&emsp;
                                      <span className="text2">
                                        {item.no_of_piller}
                                      </span>
                                    </Col>
                                  </Row>
                                )}

                                {(medium === "1" ||
                                  medium === "5" ||
                                  medium === "6") && (
                                  <Row>
                                    <Col sm={6}>
                                      <b>Media :</b>
                                      &nbsp;
                                      <span className="text2">
                                        {item.media}
                                      </span>
                                    </Col>
                                    <Col sm={6}>
                                      &nbsp;<b>Type :</b> &nbsp;
                                      <span className="text2"></span>
                                      {item.type}
                                    </Col>
                                  </Row>
                                )}
                                {medium !== "2" && medium !== "4" && (
                                  <Row>
                                    <Col sm={6}>
                                      <b>Width :</b>
                                      &nbsp;{" "}
                                      <span className="text2">
                                        {item.width}
                                      </span>
                                    </Col>
                                    <Col sm={6}>
                                      &nbsp;<b>Height :</b> &nbsp;
                                      <span className="text2">
                                        {item.height}
                                      </span>
                                    </Col>
                                  </Row>
                                )}

                                <Row>
                                  <Col>
                                    <b>Availability :</b>&emsp;
                                    <span className="text2">
                                      {item.availability}
                                    </span>
                                  </Col>
                                </Row>

                                {showRate !== "noRate" && (
                                  <Col sm={6}>
                                    <b>Rate :</b>
                                    {/* &nbsp; <span className="text2">{item.rate}</span> */}
                                    &nbsp;{" "}
                                    <span className="text2">{item.rate}</span>
                                  </Col>
                                )}

                                {showLatitude !== "false" &&
                                  (medium === "1" ||
                                    medium === "5" ||
                                    medium === "6") && (
                                    <Row>
                                      <Col sm={6}>
                                        <b>Latitude and Longitude :</b>
                                      </Col>
                                      <Col sm={6}>
                                        <a
                                          href={item.lat_long}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          {
                                            item.lat_long
                                              .split("/")
                                              .slice(-1)[0]
                                              .split(",")[0]
                                          }{" "}
                                          &{" "}
                                          {
                                            item.lat_long
                                              .split("/")
                                              .slice(-1)[0]
                                              .split(",")[1]
                                          }
                                        </a>
                                      </Col>
                                    </Row>
                                  )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <div className="mt-4 text-center">No data found</div>
                  )}

                  {total_page_count > 1 && (
                    <div className="filter-pagination">
                      <Pagination
                        count={total_page_count}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </div>
                  )}
                  <div className="download-button pt-3">
                    <Button color="success" onClick={() => generatePPT()}>
                      <FaDownload />
                      <span>PPT</span>
                      {/* &nbsp; {loaderFor.ppt && (
                  <TailSpin color="#fff" height={20} width={20} />
                )} */}
                    </Button>

                    <Button color="success" onClick={() => generatePDF()}>
                      <FaDownload />
                      <span> PDF (PPT)</span>
                    </Button>

                    <Button color="success" onClick={() => exportExcel()}>
                      <FaDownload />
                      <span>Excel</span>
                    </Button>

                    <Button color="success" onClick={() => generatePDFtable()}>
                      <FaDownload />
                      <span> PDF (Excel)</span>
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
          {imgModal && (
            <Lightbox
              mainSrc={imgURL}
              onCloseRequest={() => setImgModal(false)}
            />
          )}
        </div>
      )}
    </div>
  );
}
