import React, { useState } from "react";
import Select from "react-select";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {"   "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const allOption = {
  label: "Select All",
  value: "*",
};

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}{" "}
      </components.Placeholder>
      {children[1]}
    </components.ValueContainer>
  );
};

function DropDown(props) {
  const options = props.optionData.map((data) => ({
    label: data,
    value: data,
  }));
  const [n, setN] = useState(options.length);
  const [optionSelected, setOptionSelected] = useState(options);

  const handleChange = (selected) => {
    console.log("first");

    setOptionSelected(selected);
    props.onSelect(selected, props.for);
  };
  return (
    <Select
      isMulti
      components={{
        Option,
        ValueContainer,
      }}
      options={[allOption, ...options]}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      onChange={(selected, event) => {
        console.log(selected);
        if (selected !== null && selected.length > 0) {
          if (selected[selected.length - 1].value === allOption.value) {
            console.log("all option selected", 68);
            setN(`All ${options.length}`);
            return handleChange(options);
          }
          let result = [];
          console.log(73);
          if (selected.length === options.length) {
            if (selected.includes(allOption)) {
              console.log("all option selected", 75);
              setN(`All ${options.length}`);
              result = selected.filter(
                (option) => option.value !== allOption.value
              );
            } else if (event.action === "select-option") {
              console.log(80);
              setN(`All ${options.length}`);
              result = options;
            }
            if (result.length === 0) setN(0);
            return handleChange(result);
          }
        }
        console.log(86);
        setN(selected.length);
        return handleChange(selected);
      }}
      placeholder={<div>{n} Options Selected</div>}
      value={optionSelected}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            text: "orangered",
            primary: "#9ed2ff",
          },
        };
      }}
    />
  );
}

export default DropDown;
