import React from "react";
import "./CustomLoader.css";
import { Spinner } from "reactstrap";

const CustomLoader = () => {
  return (
    <>
      <div className="loader_alignment">
        {/* <TailSpin
          height="40"
          width="40"
          // color="#4fa94d"
          color="#E36221"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> */}
        
        <Spinner color="primary">Loading...</Spinner>
      </div>
    </>
  );
};
export default CustomLoader;
