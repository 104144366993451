import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { MediumList, Rates } from "../../constants/data";
import CustomLoader from "../../Components/customLoader/CustomLoader";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import "./FilterSelection.css";
import DropDown from "../../Components/MultiSelect/DropDown";

const initialFilter = {
  medium: "1",
  idno: [],
  zone: [],
  area: [],
  location: [],
  total_sq_ft: [], // totalSqFt
  station_name: [], // stationName
  size: [], // indicatorSize
  number_indicators: [], // indicatorNum
  media: [],
  type: [],
  width: [],
  height: [],
  availability: [],
};

const initialOptionList = {
  PPTdata: "",
  MediumList: [],
  idno: [],
  zone: [],
  area: [],
  media: [],
  type: [],
  location: [],
  availability: [],
  width: [],
  height: [],
  number_indicators: [],
  size: [],
  station_name: [],
  no_of_piller: [],
  total_sq_ft: [],
};

const initialSortedOption = {
  idno: [],
  area: [],
  media: [],
  type: [],
  location: [],
  availability: [],
  width: [],
  height: [],
  total_sq_ft: [],
  no_of_piller: [],
};

export const FilterSelection = ({ applyFilter, user_details, setShowAlert }) => {
  const [filter, setFilter] = useState(initialFilter);
  const [optionListObj, setOptionListObj] = useState(initialOptionList);
  const [sortedOptions, setSortedOptions] = useState(initialSortedOption);
  const [filterLoading, setFilterLoading] = useState(false);
  const [sortPPT, setSortPPT] = useState([]);
  const [rateOption, setRateOption] = useState(100);
  const [showLatLong, setShowLatLong] = useState(true);
  const [loadingFor, setLoadingFor] = useState({
    area: false,
    idno: false,
    location: false,
    media: false,
    type: false,
    width: false,
    height: false,
    availability: false,
  });

  const navigate = useNavigate();

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const getSortedData = (selectedList, name) => {
    let sortedarea = [];
    if (name === "zone") {
      selectedList.forEach((item) => {
        let arr = sortPPT.filter((i) => i.zone_name === item.value);
        arr = arr.map((i) => i.area);
        arr = removeDuplicates(arr);
        arr.forEach((i) => sortedarea.push(i));
      });
    }

    if (name === "area" || name === "zone") {
      let sortedIdno = [];
      let sortedLocation = [];
      let sortedAvailability = [];
      let sortedMedia = [];
      let sortedType = [];
      let sortedWidth = [];
      let sortedHeight = [];
      let sortedSqFeet = [];
      let sortedPilleresNum = [];
      selectedList.forEach((item) => {
        let arr = [];
        if (name === "zone" && filter.medium === "1")
          arr = sortPPT.filter((i) => i["zone_name"] === item.value);
        else arr = sortPPT.filter((i) => i[name] === item.value);
        let arr1 = arr.map((i) => i.idno);
        let arr2 = arr.map((i) => i.location);
        let arr3 = arr.map((i) => i.availability);
        sortedIdno = sortedIdno.concat(arr1);
        sortedLocation = sortedLocation.concat(arr2);
        sortedAvailability = sortedAvailability.concat(arr3);
        if (
          filter.medium === "1" ||
          filter.medium === "5" ||
          filter.medium === "6"
        ) {
          let arr4 = arr.map((i) => i.media);
          let arr5 = arr.map((i) => i.type.trim());
          let arr6 = arr.map((i) => i.width);
          let arr7 = arr.map((i) => i.height);
          sortedMedia = sortedMedia.concat(arr4);
          sortedType = sortedType.concat(arr5);
          // arr5.forEach((i) => sortedType.push(i));
          sortedWidth = sortedWidth.concat(arr6);
          sortedHeight = sortedHeight.concat(arr7);
        }
        if (filter.medium === "2") {
          let arr4 = arr.map((i) => i["total_sq_ft"]);
          sortedSqFeet = sortedSqFeet.concat(arr4);
        }
        if (filter.medium === "6") {
          let arr4 = arr.map((i) => i["no_of_piller"]);
          sortedPilleresNum = sortedPilleresNum.concat(arr4);
        }
      });
      sortedIdno = removeDuplicates(sortedIdno);
      sortedLocation = removeDuplicates(sortedLocation);
      sortedAvailability = removeDuplicates(sortedAvailability);
      if (
        filter.medium === "1" ||
        filter.medium === "5" ||
        filter.medium === "6"
      ) {
        sortedMedia = removeDuplicates(sortedMedia);
        sortedType = removeDuplicates(sortedType);
        sortedWidth = removeDuplicates(sortedWidth);
        sortedWidth.sort((a, b) => a - b);
        sortedWidth = sortedWidth.map((i) => `${i}`);
        sortedHeight = removeDuplicates(sortedHeight);
        sortedHeight.sort((a, b) => a - b);
        sortedHeight = sortedHeight.map((i) => `${i}`);
      }
      if (filter.medium === "2") {
        sortedSqFeet = removeDuplicates(sortedSqFeet);
      }
      if (filter.medium === "6") {
        sortedPilleresNum = removeDuplicates(sortedPilleresNum);
      }
      // console.log(sortedHeight, 143);
      let ans1 = {
        ...sortedOptions,
        idno: sortedIdno,
        location: sortedLocation,
        availability: sortDates(sortedAvailability),
        media: sortedMedia,
        type: sortedType,
        width: sortedWidth,
        height: sortedHeight,
        total_sq_ft: sortedSqFeet,
        no_of_piller: sortedPilleresNum,
      };
      let ans2 = {
        ...loadingFor,
        idno: true,
        location: true,
        availability: true,
        media: true,
        type: true,
        width: true,
        height: true,
        total_sq_ft: true,
        no_of_piller: true,
      };
      if (name === "zone") {
        setSortedOptions({ ...ans1, area: sortedarea });
        setLoadingFor({ ...ans2, area: true });
      } else {
        setSortedOptions(ans1);
        setLoadingFor(ans2);
      }

      setTimeout(() => {
        setLoadingFor({
          ...loadingFor,
          idno: false,
          location: false,
          availability: false,
          media: false,
          type: false,
          width: false,
          height: false,
          total_sq_ft: false,
          area: false,
          no_of_piller: false,
        });
      }, 1000);
    }

    if (
      name === "media" ||
      name === "type" ||
      name === "width" ||
      name === "height" ||
      name === "total_sq_ft" ||
      name === "no_of_piller"
    ) {
      let sortedIdno = [];
      let sortedLocation = [];
      let sortedAvailability = [];
      let sort1 = [];
      if (filter.area.length > 0) {
        filter.area.forEach((item) => {
          let arr = sortPPT.filter((i) => i.area === item.value);
          sort1 = sort1.concat(arr);
        });
      } else if (filter.zone.length > 0) {
        filter.zone.forEach((item) => {
          let arr = sortPPT.filter((i) => i.zone_name === item.value);
          sort1 = sort1.concat(arr);
        });
      } else {
        sort1 = [...sortPPT];
      }
      // console.log(sort1, filter.area);
      selectedList.forEach((item) => {
        let arr = sort1.filter((i) => i[name] === item.value);
        let arr1 = arr.map((i) => i.idno);
        let arr2 = arr.map((i) => i.location);
        let arr3 = arr.map((i) => i.availability);
        sortedIdno = sortedIdno.concat(arr1);
        sortedLocation = sortedLocation.concat(arr2);
        sortedAvailability = sortedAvailability.concat(arr3);
      });
      // console.log(sortedarea, selectedList);
      sortedIdno = removeDuplicates(sortedIdno);
      sortedLocation = removeDuplicates(sortedLocation);
      sortedAvailability = removeDuplicates(sortedAvailability);
      setSortedOptions({
        ...sortedOptions,
        idno: sortedIdno,
        location: sortedLocation,
        availability: sortDates(sortedAvailability),
      });
      setLoadingFor({
        ...loadingFor,
        idno: true,
        location: true,
        availability: true,
      });
      setTimeout(() => {
        setLoadingFor({
          ...loadingFor,
          idno: false,
          location: false,
          availability: false,
        });
      }, 1000);
    }
  };

  const handleFilterOptions = (selectedList, name) => {
    if (selectedList.length > 0 && selectedList[0]["value"] === "*")
    selectedList.shift();
    console.log(selectedList);
    filter[name] = selectedList;
    let obj = { ...filter };
    // if (name === "zone") obj = { ...initialFilter, zone: selectedList };
    // if (name === "area")
    //   obj = { ...initialFilter, zone: filter.zone, area: selectedList };
    setFilter(obj);
    getSortedData(selectedList, name);
  };

  const sortDates = (dates) => {
    let dateArr = [];
    let dateArr2 = [];

    dates.map((i) => {
      if (i.charAt(2) === "." && i.charAt(5) === ".") {
        let d1 = `20${i.slice(6, 8)}-${i.slice(3, 5)}-${i.slice(0, 2)}`;
        dateArr.push({ d1: new Date(d1), d2: i.slice(8) });
      } else dateArr2.push(i);
    });
    dateArr.sort(function (a, b) {
      return a.d1 - b.d1;
    });
    dateArr = dateArr.map((i) => {
      let day = i.d1.getDate();
      let month = i.d1.getMonth() + 1;
      return (
        `${day < 10 ? "0" + day : day}.${
          month < 10 ? "0" + month : month
        }.${i.d1.getFullYear().toString().slice(2,4)}` + i.d2
      );
    });
    return [...dateArr, ...dateArr2];
  };

  useEffect(() => {
    getMediumData("1", false);
  }, []);

  const getMediumData = async (e, bool) => {
    let m = "",
      m2 = "";
    if (e === "1") {
      m = "Billboards";
      m2 = "1";
    } else {
      m2 = e.target.value;
      MediumList.forEach((i) => {
        if (i.id === m2) m = i.name2;
      });
    }
    // mehsana-bus stand, indicators, railway station, brts bus station, vadodara pillers
    setFilterLoading(true);
    handleFilterOptions(m2, "medium");
    // apply filter for page1
    // console.log(e);
    try {
      let formData = new FormData();
      formData.append("token", routes.TOKEN);
      formData.append("session_token", user_details.session_token);
      formData.append("medium", m);
      const dataRes = await fetch(`${routes.API_URL}${routes.MEDIUM}`, {
        method: "POST",
        body: formData,
      });
      const json = await dataRes.json();
      if (json) {
        console.log(json);
        if (json.status_code === "2") {
          setShowAlert({open: true, message:json.message})
          localStorage.removeItem("user_details");
          localStorage.removeItem("filterObj");
          navigate("/login");
        }
        if (json.status_code === "1") {
          getSortFilter({ medium: m });
          let obj1 = json.data;
          function removeDuplicates(arr) {
            return arr.filter((item, index) => arr.indexOf(item) === index);
          }
          for (let x in obj1) {
            if (x === "total_square_feet")
              obj1["total_sq_ft"] = removeDuplicates(obj1[x]);
            obj1[x] = removeDuplicates(obj1[x]);
            if (x === "availability") obj1[x] = sortDates(obj1[x]);
          }
          // console.log("json", json, "obj1", obj1);
          setOptionListObj(obj1);
          setSortedOptions({ ...sortedOptions, ...obj1 });
          setFilter({ ...initialFilter, medium: m2 });
        }
        else{
          setShowAlert({open: true, message:json.message})
        }
        // setFilterLoading(false);
      }
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
      setShowAlert({open: true, message:error.message})
    }
    // setTimeout(()=>{
    //   setFilterLoading(false);
    // },1000)
  };

  const getSortFilter = async (filterObj) => {
    try {
      let formData = new FormData();
      formData.append("token", routes.TOKEN);
      formData.append("session_token", user_details.session_token);
      formData.append("medium", filterObj.medium);
      const dataRes = await fetch(`${routes.API_URL}${routes.FILTER}`, {
        method: "POST",
        body: formData,
      });
      const json = await dataRes.json();
      if (json) {
        // console.log(json);
        if (json.status_code === "1") {
          setSortPPT(json.data);
          setFilterLoading(false);
        }
        if (json.status_code === "2") {
          localStorage.removeItem("user_details");
          localStorage.removeItem("filterObj");
          navigate("/login");
        }
      }
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
      setShowAlert({open: true, message:error.message})
    }
  };

  const Idoptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp;Select Id:</label>
        <br />
        {loadingFor.idno ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="idno"
            // optionData={optionListObj.area}
            optionData={sortedOptions.idno}
          />
        )}
      </div>
    </Col>
  );

  const ZoneOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp;Zone:</label>
        <br />
        <DropDown
          onSelect={handleFilterOptions}
          for="zone"
          optionData={optionListObj.zone}
        />
      </div>
    </Col>
  );

  const AreaOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp;Area:</label>
        <br />
        {loadingFor.area ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="area"
            optionData={sortedOptions.area}
          />
        )}
      </div>
    </Col>
  );

  const AreaOptions2 = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp;Area:</label>
        <br />
        <DropDown
          onSelect={handleFilterOptions}
          for="area"
          optionData={optionListObj.area}
        />
      </div>
    </Col>
  );

  const LocationOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp;Location:</label>
        <br />
        {/* <DropDown
          onSelect={handleFilterOptions}
          for="location"
          optionData={optionListObj.location}
        /> */}
        {loadingFor.location ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="location"
            optionData={sortedOptions.location}
          />
        )}
      </div>
    </Col>
  );

  const availability = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect" style={{ marginBottom: "30px" }}>
        <label>&nbsp;Availability:</label>
        <br />
        {loadingFor.availability ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="availability"
            optionData={sortedOptions.availability}
          />
        )}
      </div>
    </Col>
  );

  const MediaOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Media:</label>
        <br />
        {/* <DropDown
          onSelect={handleFilterOptions}
          for="media"
          optionData={optionListObj.media}
        /> */}
        {loadingFor.media ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="media"
            optionData={sortedOptions.media}
          />
        )}
      </div>
    </Col>
  );

  const TypeOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Type:</label>
        <br />
        {loadingFor.type ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="type"
            optionData={sortedOptions.type}
          />
        )}
      </div>
    </Col>
  );

  const TypeOptions2 = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Type:</label>
        <br />
        <DropDown
          onSelect={handleFilterOptions}
          for="type"
          optionData={optionListObj.type}
        />
      </div>
    </Col>
  );

  const WidthOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Width:</label>
        <br />
        {loadingFor.width ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="width"
            optionData={sortedOptions.width}
          />
        )}
      </div>
    </Col>
  );

  const WidthOptions2 = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Width:</label>
        <br />
        <DropDown
          onSelect={handleFilterOptions}
          for="width"
          optionData={optionListObj.width}
        />
      </div>
    </Col>
  );

  const HeightOptions = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Height:</label>
        <br />

        {loadingFor.height ? (
          <Spinner color="primary">Loading...</Spinner>
        ) : (
          <DropDown
            onSelect={handleFilterOptions}
            for="height"
            optionData={sortedOptions.height}
          />
        )}
      </div>
    </Col>
  );

  const HeightOptions2 = (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="dropdownSelect">
        <label>&nbsp; Height:</label>
        <br />
        <DropDown
          onSelect={handleFilterOptions}
          for="height"
          optionData={optionListObj.height}
        />
      </div>
    </Col>
  );

  return (
    <div id="filter">
      <div>
        <label
          style={{ marginRight: "20px", fontSize: "20px", fontWeight: "500" }}
        >
          &nbsp;Select Medium:
        </label>
        <select
          name="medium"
          value={filter.medium}
          onChange={(e) => getMediumData(e, true)}
        >
          <option value="" disabled>
            --Select Medium--
          </option>
          {MediumList?.map((item, index) => (
            <option key={item.name} value={item?.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <hr className="mt-0" />
      {filterLoading ? (
        <CustomLoader />
      ) : (
        <div className="p-2">
          {filter.medium === "1" && (
            <Row>
              {/* <p>
                Notes: &emsp; 1. All option selections are optional. i.e if
                there is 0 selected option for area then results will be for all
                area, based on selected zone and medium.
                <br />
                &emsp; &emsp; &emsp; &ensp; 2. Options for Media, Type,Width,
                Height, Id, Location, Availability will be displayed after
                selecting minimum 1 zone and area.
              </p> */}
              {ZoneOptions}
              {AreaOptions}
              <Row>
                {MediaOptions}
                {TypeOptions}
                {WidthOptions}
                {HeightOptions}
              </Row>
              {Idoptions}
              {LocationOptions}
              {availability}
            </Row>
          )}

          {filter.medium === "2" && (
            <Row>
              {AreaOptions2}
              <Row>
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <div className="dropdownSelect">
                    <label>&nbsp; Total Square Feet:</label>
                    <br />
                    {loadingFor.total_sq_ft ? (
                      <Spinner color="primary">Loading...</Spinner>
                    ) : (
                      <DropDown
                        onSelect={handleFilterOptions}
                        for="total_sq_ft"
                        // optionData={sortedOptions.idno}
                        optionData={sortedOptions.total_sq_ft}
                      />
                    )}
                  </div>
                </Col>
                {Idoptions}
                {LocationOptions}
                {availability}
              </Row>

              {/* {RateOptions} */}
              {/* {ImageOptions} */}
            </Row>
          )}

          {filter.medium === "3" && (
            <Row>
              {WidthOptions2}
              {HeightOptions2}
              {TypeOptions2}
              <Row>
                {Idoptions}
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <div className="dropdownSelect">
                    <label>&nbsp; Station Name:</label>
                    <br />
                    <DropDown
                      onSelect={handleFilterOptions}
                      for="station_name"
                      optionData={optionListObj.station_name}
                    />
                  </div>
                </Col>
              </Row>
              {availability}
              {/* {RateOptions} */}
              {/* {ImageOptions} */}
            </Row>
          )}

          {filter.medium === "4" && (
            <Row>
              {AreaOptions2}
              <Col xs={12} sm={6} lg={4} xl={3}>
                <div className="dropdownSelect">
                  <label>&nbsp; Size:</label>
                  <br />
                  <DropDown
                    onSelect={handleFilterOptions}
                    for="size"
                    optionData={optionListObj.size}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} lg={4} xl={3}>
                <div className="dropdownSelect">
                  <label>&nbsp; Number Of Indicators:</label>
                  <br />
                  <DropDown
                    onSelect={handleFilterOptions}
                    for="number_indicators"
                    optionData={optionListObj.number_indicators}
                  />
                </div>
              </Col>
              {LocationOptions}
              {Idoptions}
              {availability}
              {/* {RateOptions} */}
              {/* {ImageOptions} */}
            </Row>
          )}

          {filter.medium === "5" && (
            <Row>
              {AreaOptions}
              <Row>
                {MediaOptions}
                {TypeOptions}
                {WidthOptions}
                {HeightOptions}
              </Row>
              {Idoptions}
              {LocationOptions}
              {availability}
              {/* {RateOptions} */}

              {/* <Col xs={6} lg={4} xl={3}>
            <div style={{ width: "270px", marginBottom: "30px" }}>
              <label>&nbsp; Latitude:</label>
              <br />
              <DropDown
                onSelect={handleFilterOptions}
                for="latitude"
                optionData={LatitudeList}
              />
            </div>
          </Col>

          <Col xs={6} lg={4} xl={3}>
            <div style={{ width: "270px", marginBottom: "30px" }}>
              <label>&nbsp; Longitude:</label>
              <br />
              <DropDown
                onSelect={handleFilterOptions}
                for="longitude"
                optionData={LongitudeList}
              />
            </div>
          </Col>

          {ImageOptions} */}
            </Row>
          )}

          {filter.medium === "6" && (
            <Row>
              {AreaOptions}
              <Row>
                {WidthOptions}
                {HeightOptions}
                {/* {TypeOptions} */}
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <div className="dropdownSelect">
                    <label>&nbsp;Total No. of Pillers:</label>
                    <br />
                    {/* <DropDown
                      onSelect={handleFilterOptions}
                      for="no_of_piller"
                      optionData={optionListObj.no_of_piller}
                    /> */}
                    {loadingFor.total_sq_ft ? (
                      <Spinner color="primary">Loading...</Spinner>
                    ) : (
                      <DropDown
                        onSelect={handleFilterOptions}
                        for="no_of_piller"
                        // optionData={sortedOptions.idno}
                        optionData={sortedOptions.no_of_piller}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {Idoptions}
              {LocationOptions}
              {availability}
            </Row>
          )}

          <Row className="align-items-center mt-2">
            {/* {filter.medium === "3" ? availability2 : availability} */}

            <Col sm={12} md={6} lg={3}>
              <label>Rate(%):</label> &emsp;
              <select
                value={rateOption}
                onChange={(e) => {
                  setRateOption(e.target.value);
                }}
              >
                <option value="" disabled>
                  --Select Rate--
                </option>
                {Rates?.map((item, index) => (
                  <option key={index} value={item?.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </Col>
            {(filter.medium === "1" || filter.medium === "5") && (
              <Col sm={12} md={6}>
                <label>Show Latitude and Longitude:</label> &emsp;
                <select
                  value={showLatLong}
                  onChange={(e) => {
                    setShowLatLong(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    --Select option--
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </Col>
            )}
          </Row>

          <div className="filterButton">
            <Button
              color="secondary"
              onClick={() => {
                console.log("first");
                setFilterLoading(true);
                setShowLatLong(true);
                setRateOption(100);
                setFilter({ ...initialFilter, medium: filter.medium });
                setSortedOptions({ ...initialSortedOption, ...optionListObj });
                setTimeout(() => {
                  setFilterLoading(false);
                }, 1000);
              }}
            >
              Reset Filter
            </Button>

            <Button
              color="success"
              onClick={() => {
                localStorage.setItem("filterObj", JSON.stringify(filter));
                console.log(filter, 906);
                localStorage.setItem("ratePercentage", rateOption);
                localStorage.setItem("showLatitude", showLatLong);
                applyFilter(filter);
              }}
            >
              Apply Filter
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
