import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import logo from "../../assets/images/Logo.png";
import CustomLoader from "../../Components/customLoader/CustomLoader";
import { routes } from "../../constants/routes";
import "./Login.css";

export const Login = ({setShowAlert}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const inputElement = useRef();

  useEffect(() => {
    const remember = localStorage.getItem("Remember_Me");
    if (remember === "true") {
      setRememberMe(true);
      const login = JSON.parse(localStorage.getItem("login"));
      setUser(login);
    }
    if (inputElement.current) inputElement.current.focus();
  }, []);

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    // const { email, password } = document.forms[0];
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("token", routes.TOKEN);
      formData.append("userid", user.email);
      formData.append("password", user.password);
      // jon@deo.com
      const dataRes = await fetch(`${routes.API_URL}${routes.LOGIN}`, {
        method: "POST",
        body: formData,
      });
      // if (dataRes) console.log(dataRes);
      const json = await dataRes.json();
      if (json) {
        console.log(json);
        if (json.status_code === "1") {
          if (rememberMe) {
            localStorage.setItem("Remember_Me", "true");
            localStorage.setItem("login", JSON.stringify(user));
          } else {
            localStorage.setItem("Remember_Me", "false");
            localStorage.removeItem("login");
          }
          localStorage.setItem("user_details", JSON.stringify(json.data[0]));
          navigate("/filterPage");
        } else if (json.status_code === "0") {
          setErrorMessage(json.message);
          setShowAlert({open: true, message:json.message})
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowAlert({open: true, message:error.message})
    }
  };

  const togglePassword = () => {
    setShowpassword(!showPassword);
  };

  return (
    <div id="login">
      <div className="headerForLogin">
        <img src={logo} height="100%" alt="logo" />
      </div>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="login-inner">
          <div className="logo">
            <img src={logo} width="400" alt="logo" />
          </div>

          <div className="login-form">
            <div className="login-form-inner">
              <div className="mx-2">
                <h1>Sign In</h1>
                <p>
                  <i>Please sign in with your username/password</i>
                </p>
              </div>

              <form onSubmit={handleSubmit} className="form">
                <div className="input-container">
                  <label>Username* </label>
                  <input
                    type="text"
                    name="email"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    placeholder="Enter your username"
                    required
                    ref={inputElement}
                  />
                  <span className="userIcon">
                    <FaUserAlt />
                  </span>
                </div>
                <div className="input-container">
                  <label>Password*</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    placeholder="Enter your password"
                    required
                  />
                  <button
                    type="button"
                    className="showPassword"
                    onClick={togglePassword}
                  >
                    {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                  </button>
                </div>
                <div className="remember">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  &nbsp;
                  <label className="ml-2">Remember me</label>
                </div>
                <div className="button-container py-3">
                  {errorMessage && <p className="error">{errorMessage}</p>}
                  <input type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
