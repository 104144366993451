// const API_URL = process.env.REACT_APP_API_URL;

export const routes = {
  // API_URL: "https://kaushikoutdoors.com",
  API_URL: process.env.REACT_APP_API_URL,
  TOKEN: process.env.REACT_APP_TOKEN,
  LOGOUT: "/web_apis/logout",
  LOGIN: "/web_apis/login",
  MEDIUM: "/web_apis/medium",
  FILTER: "/web_apis/filter",
};
