import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Login } from "./pages/Login/Login";
import { Button, Modal, ModalBody } from "reactstrap";
import { FaTimes, FaCheck } from "react-icons/fa";
import { routes } from "./constants/routes";
import logo from "./assets/images/Logo.png";
import DownloadFiles from "./pages/DownloadFiles/DownloadFiles";
import { Alert, Snackbar } from "@mui/material";

function App() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({open:false, message:""});
  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const logout = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("token", routes.TOKEN);
      formData.append("session_token", user_details.session_token);
      const dataRes = await fetch(`${routes.API_URL}${routes.LOGOUT}`, {
        method: "POST",
        body: formData,
      });
      if (dataRes) console.log(dataRes);
      const json = await dataRes.json();
      if (json) {
        setLoading(false);
        console.log(json);
      }
      localStorage.removeItem("user_details");
      // localStorage.removeItem("login");
      // localStorage.removeItem("Remember_Me");
      localStorage.removeItem("filterObj");
      navigate("/login");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const modal1 = (
    <Modal isOpen={openModal} centered={true}>
      <ModalBody>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            padding: "20px 10px",
            fontWeight: "500",
          }}
        >
          <p>Are you sure want to logout?</p>
          <br />
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                logout();
                setOpenModal(false);
              }}
              style={{
                backgroundColor: "#009900",
                color: "white",
                padding: "5px 15px",
                border: "none",
                borderRadius: "5px",
              }}
            >
              <FaCheck style={{ margin: "0 5px" }} />
              Ok
            </button>
            <button
              onClick={() => setOpenModal(false)}
              style={{
                backgroundColor: "#efefef",
                color: "black",
                padding: "5px 15px",
                border: "none",
                marginLeft: "40px",
                borderRadius: "5px",
              }}
            >
              <FaTimes style={{ margin: "0 5px" }} />
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );

  return (
    <div id="app">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showAlert.open}
        autoHideDuration={9000}
        onClose={() => setShowAlert({open: false, message:""})}
      >
        <Alert
          onClose={() => setShowAlert({open: false, message:""})}
          severity="error"
          sx={{ width: "100%" }}
        >
         {showAlert.message}
        </Alert>
      </Snackbar>
      <Routes>
        <Route
          path="/"
          element={
            user_details ? (
              <Navigate to="/filterPage" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login setShowAlert={setShowAlert}/>} />

        <Route
          path="/filterPage"
          element={
            user_details ? (
              <DownloadFiles
                user_details={user_details}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setOpenModal}
                setShowAlert={setShowAlert}
              />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/*" element={<NotFoundPage navigate={navigate} />} />
      </Routes>
      {openModal && modal1}
    </div>
  );
}

const NotFoundPage = ({ navigate }) => {
  return (
    <div>
      <div id="header404">
        <img src={logo} width="175" alt="logo" />
      </div>
      <h1 className="m-4">404 - Page not found</h1>
      <Button className="m-4" onClick={() => navigate("/login")}>
        Go to login page
      </Button>
    </div>
  );
};

export default App;
