import React, { useState } from "react";
import { TbLogout } from "react-icons/tb";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { FaAngleDown } from "react-icons/fa";
import logo from "../../assets/images/Logo.png";
import "./Header.css"
import Lightbox from "react-image-lightbox";

export const Header = ({ setOpenModal, login, user_details }) => {
  const [imgModal, setImgModal] = useState(false);
  // console.log(user_details)
  return (
    <div id="header">
      <img src={logo} width="175" alt="logo" />
     
     {login && <div className="widget-content">
        <UncontrolledButtonDropdown >
          <DropdownToggle color="white" className="p-1">
            <div className="d-flex align-items-center user-detail-box1">
              <img
                width={35}
                height={35}
                className="rounded-circle"
                src={user_details.image}
                onClick={() => {
                  setImgModal(true);
                }}
                alt=""
              />
              <div
                className="widget-heading"
              >
                <span>&nbsp;&nbsp;{`${user_details.first_name} ${user_details.last_name}`}</span>
                <div style={{marginLeft:"10px", opacity:"0.7"}}>
                  <FaAngleDown />
                </div>
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu end className="rm-pointers dropdown-menu-lg">
            <div className="rm-pointers dropdown-menu-sm p-0">
              <DropdownItem>{`${user_details.first_name} ${user_details.last_name}`}
              <p className="mb-0">Designation: {user_details.designation_name}</p>
              <p className="mb-0">Mobile: {user_details.mobile}</p>
              <p className="mb-0">Email: {user_details.email}</p>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Logout
              </DropdownItem>
            </div>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>}

      {imgModal && (
            <Lightbox
              mainSrc={user_details.image}
              onCloseRequest={() => setImgModal(false)}
            />
          )}

    </div>
  );
};
